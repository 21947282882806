<template>
  <div 
    :style="'background-image:url('+backgroundImage+')'"
    :class="specificClassGenerator(actionLink)"
    class="company-banner"
    >
        <div class="description">
            <div class="company-info">
                <h2>{{$t(companyTitle)}}</h2>
                <p>{{$t(companyDescription)}}</p>
            </div>
             <router-link :to="actionLink" class="action-container">
                 <div class="action-link">
                    {{$t('ACTION.LEARN_MORE')}}
                 </div>
            </router-link>
        </div>
  </div>
</template>

<script>
import hengHuatLogo from '@/assets/logo/henghuat.jpg'
export default {
    name:"Intro",
    props:{
        backgroundImage:{
            type:String,
            default:""
        },
        actionLink:{
            type:String,
            default:""
        },
        companyTitle:{
            type:String,
            default:""
        },
        companyDescription:{
            type:String,
            default:""

        }
    },
    methods:{
        specificClassGenerator(link){
            switch(link){
                case '/el-cold-chain':
                    return 'el-group'
                case '/dragon-global':
                    return 'dragon-global'
                default :
                    return ''
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.el-group{
    margin: 0 auto;
    background-size: contain !important;
}

.company-banner{
    width:100%;
    height:100vh;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative ;
    border-bottom: 3px solid #f9f4f4;

    .description{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 60%;
        padding: 15px 10px;

        .company-info {
            h2{
                font-size: 4.5rem;
                font-weight: bold;
                text-shadow: 2px 2px 0px $action-color, 5px 4px 0px rgba(0,0,0,0.15);
                color: $white;
            }

            p{
                font-size: 2rem;
                text-shadow: 2px 2px 0px $action-color, 5px 4px 0px rgba(0,0,0,0.15);
                color: $white;
            }
        }

        
        .action-container{
            background-color: $white;
            border: 1px solid $white;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            padding: 15px;
            margin: 0 auto;
            cursor: pointer;
            transition: .2s all;


            .action-link{
                color: $action-color;
                text-decoration: none;
                font-size: 1.5rem;
            }

            &:hover{
                background-color: $action-color;
                border: 1px solid $action-color;
                .action-link{
                    color:$white
                }
            }


        }

    }
}

@media screen and (max-width:600px){
    .el-group{
        width: 100%;    
        background-size: cover !important;
    }

    .company-banner{
        height: 50vh;
        .description{
            width:100%;

            .company-info{
                h2{
                    font-size: 2.5rem;
                }
                p{
                    font-size: 1.5rem;
                }

            }

            .action-container{
                width: 80%;
                padding: 5px;
                background-color: $action-color;
                border: 1px solid $action-color;
                
                .action-link{
                    font-size: 1rem;
                    color:$white
                }
            }
        }
    }
}
</style>